<template>
  <span class="percent_with_arrow mr-2">
    <v-icon size=22 class="mb-1 mx-1" :color="displayedColor">{{ displayedIcon }}</v-icon>
    <b>{{ displayedPercent }}%</b>
  </span>
</template>

<style scoped>
.percent_with_arrow {
  font-size: 32px;
  /* font-weight: 500; */
}
</style>

<script>

import percentChangedWithArrowComponentModel from '@/component-models/percent-changed-with-arrow-component-model';

export default percentChangedWithArrowComponentModel;

</script>