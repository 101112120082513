<template>
          <v-row dense>
            <v-col cols=12 sm=6 md=6 lg=6>
              <witnessOptionSelector name="Clinic" 
                ref="clinicSelector"
                :singleSelectionMode="true"
                zeroSelectionLabel="None"
                :options="clinicOptions"
                @selection-updated="clinicSelectionChange">
              </witnessOptionSelector>
            </v-col>
            <v-col cols=12 sm=6 md=6 lg=6>
              <witnessOptionSelector name="Interval" 
                ref="intervalSelector"
                :singleSelectionMode="true"
                zeroSelectionLabel="None"
                :options="intervalOptions"
                :preselectedOptionIds="intervalPreselectedOptionIds"
                @selection-updated="intervalSelectionChange">
              </witnessOptionSelector>
            </v-col>
            <v-col cols=12 sm=12 md=12 lg=12 class="d-flex">
              <witnessDateFilter 
                ref="dateFilter"
                @updateDateRange="updateDateRange" 
                @requestDateJump="requestDateJump">
              </witnessDateFilter>
              <tips-and-tricks class="ml-2" goto="setting_up_an_interval"/>
            </v-col>
            <v-col v-show="intervalsExplorerIsOpen === false" cols=12 class="text-right">
                <v-btn @click="clickExplore" class="mr-11">Explore Intervals</v-btn>
            </v-col>
          </v-row>
</template>

<style scoped>

.filter-component-container {
  background-color: #FCFCFC;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 3px solid #F2F2F2;
}

</style>

<script>

import intervalFilterComponentModel from '@/component-models/interval-filter-component-model';

export default intervalFilterComponentModel;

</script>